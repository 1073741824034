<template>
  <div class="">
    <div class="customer-white">
      <skeleton-list :row="6" v-if="listLoading" class="mb-6" />
      <el-table :data="items" v-else style="width: 100%">
        <el-table-column prop="purchaseorder_no" label="N°FACTURE" width="180">
          <template slot-scope="{ row }">
            <span class="text-blue-500">{{ row.payment_no }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="purchaseorder_date" label="DATE" width="135">
          <template slot-scope="{ row }">
            <span>{{ row.date | filterTime }}</span>
          </template>
        </el-table-column>
        <el-table-column width="150" label="STATUT">
          <template slot-scope="{ row }">
            <Status :name="row.status" />
          </template>
        </el-table-column>
        <el-table-column prop="account_nmae" label="COMPTE">
          <template slot-scope="{ row }">
            <div class="flex flex-col">
              <span>{{ row.account_name }}</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="total_all_taxe"
          label="MONTANT"
          align="right"
        >
          <template slot-scope="{ row }">
            <span class="">{{ row.amount | moneyFilter }} {{user.currency_code}}</span>
          </template>
        </el-table-column>
        <el-table-column width="120" align="right">
          <template slot-scope="{ row }">
            <el-button
              circle
              size="mini"
              icon="el-icon-view"
              @click="handleView(row.payment_id)"
            ></el-button>
            <el-button
              v-if="row.status === 'PAID'"
              type="danger"
              circle
              size="mini"
              icon="el-icon-delete"
              @click="handleDelete(row)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="flex justify-end">
      <pagination
        v-show="listQuery.total > 0"
        :total="listQuery.total"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.size"
        @pagination="fetchInvoices()"
      />
    </div>

    <!-- List paiement en Bl -->
    <el-dialog
      title="Facture d'achat"
      :visible.sync="dialogPaymentList"
      width="50%"
      top="4vh"
      :close-on-click-modal="false"
    >
      <div class="customer-white">
        <el-table :data="paymentItems" style="width: 100%">
          <el-table-column prop="billNo" label="N° FACTURE" min-width="250">
          </el-table-column>

          <el-table-column
            prop="Quantité"
            align="right"
            label="MONTANT"
            width="250"
          >
            <template slot-scope="{ row }">
              <span>{{ row.amount | moneyFilter }} FCFA</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <!-- new Paiement -->
  </div>
</template>

<script>
import {
  getAllPaymentBill,
  cancelPaymentBill,
  getItemsPayment,
} from "@/api/purchase";
import Pagination from "@/components/Pagination";
import { toThousandFilter } from "@/Filters";
import { parseDate } from "@/utils";
import Status from "@/components/Status";
import SkeletonList from "@/components/Skeleton/list";
import {mapGetters} from "vuex";
export default {
  name: "Bill",
  components: { Pagination, Status, SkeletonList },
  filters: {
    filterTime: parseDate,
    moneyFilter: toThousandFilter,
  },
  data() {
    return {
      orders: [],
      listLoading: false,
      items: [],
      dialogPaymentList: false,
      dialogPaymentInvoice: false,
      paymentItems: [],
      listQuery: {
        total: 0,
        page: 1,
        size: 20,
        sort: "desc",
        sortDir: "createdAt",
        start: undefined,
        end: undefined,
        reference: undefined,
        contactId: this.$route.params && this.$route.params.id,
        status: undefined,
      },
    };
  },
  mounted() {
    this.fetchBill();
  }, computed :{
        ...mapGetters(["user"]),
    },
  methods: {
    async fetchBill() {
      this.listLoading = true;
      await getAllPaymentBill(this.listQuery)
        .then((res) => {
          setTimeout(() => {
            this.items = res.data.items;
            this.listQuery.total = parseInt(res.data.total_items);
            this.listQuery.page = parseInt(res.data.current_page) + 1;
            this.listLoading = false;
          }, 1.5 * 1000);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleDelete(row) {
      this.$confirm(
        "Vous souhaitez vraimment supprimer le document ?",
        `Supprimer le  Paiement N° ${row.payment_no}`,
        {
          distinguishCancelAndClose: true,
          dangerouslyUseHTMLString: true,
          confirmButtonText: "Supprimer",
          showClose: false,
          type: "warning",
          closeOnClickModal: false,
          cancelButtonText: "Annuler",
        }
      )
        .then(() => {
          console.log("supprimer");

          cancelPaymentBill(row.payment_id).then(() => {
            const index = this.items.findIndex(
              (el) => el.payment_id === row.payment_id
            );
            this.items.splice(index, 1);
            this.$message({
              type: "success",
              message: " Le paiement supprimmer avec success",
            });
          });
        })
        .catch(() => {
          console.log("cancel");
        });
    },
    handleView(id) {
      getItemsPayment(id).then((res) => {
        this.paymentItems = res.data;
        this.dialogPaymentList = true;
      });
    },
    handleAdd() {
      this.dialogPaymentInvoice = true;
    },
  },
};
</script>

<style></style>
