<template>
  <div class="customer-white">
    <skeleton-list :row="6" v-if="listLoading" class="mb-6" />
    <el-table :data="items" v-else style="width: 100%">
      <el-table-column prop="purchaseorder_no" label="N°FACTURE" width="160">
        <template slot-scope="{ row }">
          <router-link :to="`/purchases/bills/` + row.bill_id">
            <span class="text-blue-500">{{ row.bill_no }}</span>
          </router-link>
        </template>
      </el-table-column>
      <el-table-column prop="purchaseorder_date" label="DATE" width="200">
        <template slot-scope="{ row }">
          <span>{{ row.created_at }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="" label="ÉCHEANCE" width="200">
        <template slot-scope="{ row }">
          <div class="flex flex-col">
            <span>{{ row.bill_due_date }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="status" align="left" label="STATUT" width="200">
        <template slot-scope="{ row }">
          <Status :name="row.status" />
        </template>
      </el-table-column>

      <el-table-column prop="total_all_taxe" label="TOTAL HT" align="right">
        <template slot-scope="{ row }">
          <span class="">{{ row.total | moneyFilter }} {{user.currency_code}} </span>
        </template>
      </el-table-column>

      <el-table-column
        width="180"
        prop="total_all_taxe"
        label=" TOTAL TTC"
        align="right"
      >
        <template slot-scope="{ row }">
          <span v-if="row.total !== row.avance"
            >{{ (row.total - row.avance) | moneyFilter }}  {{user.currency_code}}
          </span>
          <span v-else> - </span>
        </template>
      </el-table-column>
    </el-table>

    <div class="flex justify-end">
      <pagination
        v-show="listQuery.total > 0"
        :total="listQuery.total"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.size"
        @pagination="fetchOrders()"
      />
    </div>
  </div>
</template>

<script>
import { getAllBill } from "@/api/purchase";
import Pagination from "@/components/Pagination";
import { toThousandFilter } from "@/Filters";
import { parseDate } from "@/utils";
import Status from "@/components/Status";
import SkeletonList from "@/components/Skeleton/list";
import {mapGetters} from "vuex";

export default {
  name: "Order",
  components: { Pagination, Status, SkeletonList },
  filters: {
    filterTime: parseDate,
    moneyFilter: toThousandFilter,
  },
  data() {
    return {
      orders: [],

      listLoading: false,

      items: [],

      listQuery: {
        total: 0,
        page: 1,
        size: 20,
        sort: "desc",
        sortDir: "createdAt",
        start: undefined,
        end: undefined,
        type: "PURCHASE_ORDER",
        orderno: undefined,
        contactId: this.$route.params && this.$route.params.id,
        status: undefined,
      },
    };
  },
  computed :{
        ...mapGetters(["user"]),
    },
  mounted() {
    this.fetchOrders();
  },

  methods: {
    async fetchOrders() {
      this.listLoading = true;
      await getAllBill(this.listQuery)
        .then((res) => {
          setTimeout(() => {
            this.items = res.data.items;
            this.listQuery.total = parseInt(res.data.total_items);
            this.listQuery.page = parseInt(res.data.current_page) + 1;
            this.listLoading = false;
          }, 1.5 * 1000);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleTab(obj) {
      const name = obj.name;
      this.listQuery.isUnpaid = undefined;
      this.listQuery.isUnpaid = false;
      switch (name) {
        case "OPEND":
          this.listQuery.status = "OPEND";
          break;

        case "DRAFT":
          this.listQuery.status = "DRAFT";
          break;

        case "CLOSED":
          this.listQuery.status = "CLOSED";
          break;
        case "CANCELED":
          this.listQuery.status = "OPEND";
          this.listQuery.isUnpaid = true;
          break;
        default:
          this.listQuery.status = undefined;
      }
      this.fetchOrders();
    },

    handleSupplier(id) {
      this.listQuery.contactId = id;
      this.fetchOrders();
    },
  },
};
</script>

<style></style>
