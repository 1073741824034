<template>
  <div>
    <div class="flex flex-col font-Inter px-10">
      <Loading v-if="loadingFull" />
      <div class="lg:flex lg:items-center lg:justify-between mb-3">
        <div class="flex-1 min-w-0 flex justify-between py-4">
          <div class="mt-1 flex flex-col justify-start items-start">
            <h2
              class="text-xl font-medium leading-7 text-gray-900 sm:text-3xl sm:truncate"
              style="text-transform: uppercase"
            >
              {{ customer.name_organisation }}
            </h2>
            <div class="text-gray-500 mt-2">
              <span>Fournisseur</span> -
              <span
                >Numéro: <label for="">{{ customer.contact_no }}</label></span
              >
            </div>
          </div>
          <div>
            <!--  <el-button>Modifier</el-button>
          <el-button class="mr-2">Exporter relever</el-button> -->
            <el-dropdown @command="handleCommand" trigger="click">
              <el-button type="primary">
                Créer<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <span class="text-blue-400"> </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="INV"
                  >Facture d'achat</el-dropdown-item
                >
                <el-dropdown-item command="ORDER"
                  >Ordre d'achat</el-dropdown-item
                >
                <el-dropdown-item command="PAY"
                  >Reglèment facture</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
      <div class="flex">
        <div class="w-1/3">
          <div
            class="bg-white shadow flex-1 p-6 mb-4 flex flex-col justify-center"
          >
            <div class="text-2xl font-semibold">
              {{ stats.total_invoice | moneyFilter }} {{user.currency_code}}
            </div>
            <div class="text-green-400 pt-2">
              ({{ stats.count_invoice }})Total facture à régler
            </div>
          </div>
          <div class="bg-white p-6 shadow flex-1 flex flex-col justify-center">
            <div class="text-2xl font-semibold">
              {{ stats.total_order | moneyFilter }} {{user.currency_code}}
            </div>
            <div class="text-yellow-400 pt-2">Total ordre d'achat en cours</div>
          </div>
        </div>
        <div class="w-2/3 shadow bg-white ml-4">
          <div class="p-6 flex flex-col">
            <div class="flex justify-start">
              <div class="flex flex-col flex-1">
                <div class="flex mb-3 mr-2">
                  <span class="mr-2 font-light w-1/3 text-gray-500"
                    >Téléphone:</span
                  >
                  <span class="text-base font-medium">
                    {{ customer.phone }}</span
                  >
                </div>
                <div class="flex mb-3">
                  <span class="mr-2 font-light w-1/3 text-gray-500"
                    >Téléphone whatsapp:</span
                  >
                  <span class="text-base float-left">
                    {{ customer.phone_whatsapp }}</span
                  >
                </div>

                <div class="flex mb-3">
                  <span class="mr-2 font-light text-gray-500 w-1/3"
                    >Adresse:</span
                  >
                  <span class="text-base"> {{ customer.street }}</span>
                </div>

                <div class="flex flex-col text-gray-500 mb-3">
                  <span class="mr-2 font-light w-1/3">Note:</span>
                  <span class="text-base">
                    {{ customer.remark }}
                  </span>
                </div>
              </div>
              <div class="flex flex-1 flex-col justify-start">
                <div class="flex mb-3">
                  <span class="mr-2 font-light text-gray-500 w-1/3"
                    >Entreprise:</span
                  >
                  <span class="text-base"> {{ customer.entreprise }}</span>
                </div>
                <div class="flex mb-3">
                  <span class="mr-2 font-light text-gray-500 w-1/3"
                    >Email:</span
                  >
                  <span class="text-base"> {{ customer.email }}</span>
                </div>
                <div class="flex mb-3">
                  <span class="mr-2 font-light text-gray-500 w-1/3"
                    >Site web:</span
                  >
                  <span class="text-base"> {{ customer.website }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-1">
        <div class="px-4 mt-6 bg-white shadow mb-6 pt-10">
          <el-tabs v-model="activeName">
            <el-tab-pane name="INVOICE" label="FACTURES"
              ><invoice v-if="activeName === 'INVOICE'" />
            </el-tab-pane>
            <el-tab-pane name="ORDER" label="ORDRE D'ACHATS"
              ><order v-if="activeName === 'ORDER'" />
            </el-tab-pane>
            <!--<el-tab-pane label="Bon de reception"><bon-reception /> </el-tab-pane> -->

            <el-tab-pane name="PAYMENT" label="REGLÈMENTS"
              ><payment v-if="activeName === 'PAYMENT'" />
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import ItemInfo from "../components/itemInfo.vue";
//import BonReception from "./components/bonreception.vue";
import Order from "./components/orderachat.vue";
import Invoice from "./components/invoice.vue";
import Payment from "./components/payment.vue";
import { getContact } from "@/api/contact";
import { fetchStatSupplier } from "@/api/purchase";
import { parseTime } from "@/utils";
import { toThousandFilter } from "@/Filters";
import Loading from "@/components/Loanding/Full";
import {mapGetters} from "vuex";
export default {
  name: "Fiche",
  filters: {
    filterTime: parseTime,
    moneyFilter: toThousandFilter,
  },
  components: { /*BonReception,*/ Order, Invoice, Payment, Loading },
  data() {
    return {
      loadingFull: false,
      stats: {
        total_invoice: 0,
        total_order: 0,
        count_order: 0,
        count_invoice: 0,
      },
      activeName: "INVOICE",

      customer: {
        active: true,
        avatar_url: null,
        contact_id: "",
        contact_no: "",
        delivery_address: null,
        email: "",
        first_name: "",
        invoice_address: null,
        is_business: true,
        last_name: "",
        name_organisation: "",
        phone: "",
        phone_whatsapp: null,
        remark: null,
        type: "",
        website: null,
      },
    };
  },
  watch: {
    activeName(val) {
      this.$router.push(`${this.$route.path}?tab=${val}`);
    },
  },
  created() {
    const tab = this.$route.query.tab;

    if (tab) {
      this.activeName = tab;
    }
  },
  mounted() {
    this.initData();
  },
    computed :{
        ...mapGetters(["user"]),
    },
  methods: {
    initData() {
      this.loadingFull = true;
      setTimeout(() => {
        this.FETCH_BALANCE();
        this.FETCH_CUSTOMER();
        this.loadingFull = false;
      }, 1.5 * 1000);
    },
    async FETCH_CUSTOMER() {
      const id = this.$route.params && this.$route.params.id;
      await getContact(id).then((res) => {
        this.customer = Object.assign({}, res.data);
      });
    },

    async FETCH_BALANCE() {
      const id = this.$route.params && this.$route.params.id;
      await fetchStatSupplier(id).then((res) => {
        this.stats = Object.assign({}, res.data);
      });
    },

    handleCommand(e) {
      let customerInfo = {
        contact_id: this.customer.contact_id,
        phone: this.customer.phone,
        email: this.customer.email,
        first_name: this.customer.first_name,
        last_name: this.customer.last_name,
        street: this.customer.street,
        contact_no: this.customer.contact_no,
        contact_name: this.customer.name_organisation,
      };
      switch (e) {
        case "INV": {
          this.$router.push({
            name: "create-bill",
            params: { supplier: customerInfo },
          });
          break;
        }

        case "ORDER":
          this.$router.push({
            name: "create-purchase-order",
            params: { supplier: customerInfo },
          });
          break;

        case "PAY":
          this.$router.push({
            name: "payment-bill",
            params: { supplier: customerInfo },
          });
          break;
      }
    },
  },
};
</script>

<style></style>
